<template>
  <el-card shadow="never" style="height: 100%;">
    <el-row>
      <el-col>
        <el-form
            id="change"
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
        >
          类别：
          <el-select clearable v-model="typeid" placeholder="请选择"  style="margin-right: 10px">
            <el-option
                v-for="item in typeOptions"
                :key="item.id"
                :label="item.value"
                :value="item.label"
            >
            </el-option>
          </el-select>
          所属客户：
          <el-select v-model="customerId" placeholder="请选择">
            <el-option
                v-for="item in customerOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          日期：
          <el-select clearable v-model="data" placeholder="请选择"  style="margin-right: 10px">
            <el-option
                v-for="item in dataOptions"
                :key="item.id"
                :label="item.value"
                :value="item.label"
            >
            </el-option>
          </el-select>
          <el-button
              type="primary"
              @click="showLineCharts()"
              style="background: #1d90ff; border: none"
          >查询</el-button
          >
        </el-form>
      </el-col>
      <el-col>
        <div id="myChart" style="width: 800px; height: 300px;margin-top:10px;">
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";
export default {
  methods: {
    showLineCharts(){
      if(this.customerId == null ){
       this.$message("请先选择客户")
      }else{
      this.$api.get(
          "/taobao/feedback",
          null,
          {custId: this.customerId, 
          day: this.data,
          tableName: this.typeid, 
           },
          (successRes) => {
            const myChartdata = successRes.data;
            if(myChartdata.length > 0){
              this.myChartOption.xAxis.data = myChartdata.map((data) => data.x);
              this.myChartOption.series[0].data = myChartdata.map((data) => data.y);
               this.myChart = this.$echarts.init(document.getElementById('myChart'))
               this.myChart.setOption(this.myChartOption);
            }
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

      }   
    },
    getCustomerList() {
      
      this.$api.get(
          "/customer/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
     
  },
  data() {
    return {
      customerOptions: [],
      customerId: null,
      data:"",
      dataOptions: [
         {
          id: 0,
          value: "近七天",
          label: "7",
        },
         {
          id: 1,
          value: "近15天",
          label: "15",
        },
      ],
      typeOptions: [
         {
          id: 0,
          value: "选词助手",
          label: "dictionHelper",
        },
         {
          id: 1,
          value: "生意参谋",
          label: "categoryCompass",
        },
        {
          id: 2,
          value: "商品点击明细",
          label: "commodityClick",
        },
         {
          id: 3,
          value: "商品展示明细",
          label: "commodityShow",
        },
        {
          id: 4,
          value: "搜索词点击明细",
          label: "searchWordClick",
        },
         {
          id: 5,
          value: "搜索词展示明细",
          label: "searchWordShow",
        },
      ],
      typeid: "",
      value: "",
       myChartOption:{
            title: { text:'上传数量' },
            tooltip: {},
            xAxis: {
              name:"日期",
                data: ["3-31","4-1","4-2","4-3","4-4","4-5"]
            },
            yAxis: {
               name:"数量",
            },
            series: [{
                name: '销量',
                type: 'line',
                data: []
                
            }]
        },

      
    };
  },
  mounted() {
    this.getCustomerList();
  },
}
</script>